<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <div class="frame-zone">

      <laveo-divider title="Import des prestations" icon="file-excel"></laveo-divider>

      <div class="header" *ngIf="this.userCanImport">
        <nz-upload [(nzFileList)]="importFileList" (nzFileListChange)="handleChange($event)" [nzCustomRequest]="import"
                   [nzAccept]="'.xlsx,.xls'" >
          <button
            nz-button
            nzType="primary"
            nzSize="large" (click)="resetAllProgresses()"
          ><i nz-icon nzType="upload"></i>Importer des prestations (.xlsx)
          </button>
        </nz-upload>
        <button
          nz-button
          nzType="primary"
          nzSize="large"
          (click)="exportExcel()"
          class="ml-8"><i nz-icon nzType="download"></i>Télécharger le modèle avec la liste des véhicules
        </button>

      </div>
    </div>

    <div class="import-detail-zone">

      <laveo-progress-notifier #importNotifier title="Détail de l'import Excel" queue="ExcelServiceImportProgress"/>
      <laveo-progress-notifier #exportNotifier title="Détail de l'export Excel" queue="ExcelServiceExportProgress"/>
    </div>


    <nz-modal
      [(nzVisible)]="importErrorModalVisible"
      (nzOnOk)="closeImportErrorModal()"
      (nzOnCancel)="closeImportErrorModal()"

      nzTitle="Erreur d'import pour {{ importErrors?.length }}  prestations">
      <ng-container  *nzModalContent>

        <ul>
          <li *ngFor="let error of importErrors!">{{ error.message }}</li>
        </ul>
      </ng-container>
    </nz-modal>
  </ng-template>
</nz-card>
