import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Subscription} from 'rxjs';
import {UserRight, UserRightCategory} from 'src/app/shared/models/entities/user-right';
import {UserRole, UserRoleType} from 'src/app/shared/models/entities/user-role';
import {UserService} from 'src/app/shared/services/api/user.service';
import {getEnvironmentName} from 'src/app/shared/services/helper.service';
import {setUser} from '@sentry/angular-ivy';
import {ApiErrorMessageUtil} from '../../shared/utils/api-error-message.util';
import {environment} from 'src/environments/environment';
import {Customer} from "../../shared/models/entities/customer";

@Component({
  selector: 'laveo-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit, OnDestroy {
  userRolesAreLoading = true;
  userRoles: UserRole[];
  currentRole?: UserRole;
  menuRights: UserRightCategory[];
  isMobileMenuVisible = false;
  environmentString?: string;
  userCanReadVehicles = false;
  logo?: string;
  userCanSeeReporting = false;
  userCanSeeInvoices = false;
  userCanSeeProviderInvoiceOrder = false;
  isAdmin = false;
  userId?: string;
  userCanImportService = false;

  private userSubscription: Subscription;
  private hiddenCategories: string[] = [
    '__typename',
    UserRightCategory.services.toString(),
    UserRightCategory.vehicles.toString(),
    UserRightCategory.providerInvoiceOrders.toString(),
    UserRightCategory.invoiceRequests.toString(),
  ];

  constructor(
    public router: Router,
    private readonly userService: UserService,
    private readonly message: NzMessageService
  ) {
  }

  public get canCreateVehicle(): boolean {
    return this.currentRole?.rights?.vehicles?.includes(UserRight.create) ?? false;
  }

  public get canCreateService(): boolean {
    return this.currentRole?.rights?.services?.includes(UserRight.create) ?? false;
  }

  public get bugSubject(): string {
    return encodeURI(
      `Rapport de bug Lavéo`
    );
  }

  public get bugBody(): string {
    return encodeURI(
      `Merci d'écrire une courte description du problème rencontré et de décrire les étapes pour le reproduire :\n\n\nMerci de laisser les données ci-dessous intactes.\n` + navigator.userAgent
    );
  }

  ngOnInit(): void {
    this.loadRoles();
    this.setEnv();
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  toggleMobileMenu(): void {
    this.isMobileMenuVisible = !this.isMobileMenuVisible;
  }

  setRole(roleId: string): void {
    if (!this.currentRole?.id || roleId === this.currentRole?.id) {
      return;
    }

    this.userService.setCurrentRole(roleId);
    window.location.reload();
  }

  private loadRoles(): void {
    this.userSubscription = this.userService.currentUser().subscribe({
      next: result => {
        this.userRolesAreLoading = result.loading;
        this.userRoles = result.data?.roles;

        setUser({
          id: result.data?.id,
          email: result.data?.mail,
          username: result.data?.name
        });

        this.userService.currentRole.subscribe(role => {
          this.currentRole = role;
          console.log("Current role :", this.currentRole);
          this.logo = role.logo?.urlWithDomain;
          this.isAdmin = role.type === UserRoleType.admin
          this.userCanReadVehicles = this.currentRole.rights.vehicles.includes(UserRight.read);
          this.menuRights = Object.keys(this.currentRole.rights).filter(r => !this.hiddenCategories.includes(r) && this.currentRole?.rights[r].length > 0).map(r => UserRightCategory[r]);
          this.userCanSeeReporting = [
            UserRoleType.admin,
            UserRoleType.customer,
            UserRoleType.customerSite,
            UserRoleType.customerSiteRead,
            UserRoleType.structure,
            UserRoleType.structureRead
          ].includes(role.type);


          this.userCanSeeInvoices = this.isAdmin ||
            this.currentRole.rights.invoiceRequests.includes(UserRight.read);

          // We exclude the admin to avoir getting the menu in double
          this.userCanSeeProviderInvoiceOrder =
            this.currentRole.rights.providerInvoiceOrders.includes(UserRight.read) && !this.isAdmin;


          if (!environment.flags.enableReporting) {
            this.userCanSeeReporting = false;
          }

          // Get the import right if the user is a customer
          if (role.type === UserRoleType.customer) {
            const customer = role.actor as Customer;
            this.userCanImportService = customer.allowImportServices ?? false;
            this.userId = role.actor.id;

          }
        });
      },
      error: error => {
        console.error(error);
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
        this.userRolesAreLoading = false;
      }
    });
  }

  private setEnv(): void {
    this.environmentString = getEnvironmentName();
  }
}
