import { Component, Input, OnInit } from '@angular/core';
import { Customer } from 'src/app/shared/models/entities/customer';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { imageFileUploadOptions } from 'src/app/configs/file-upload.config';
import { GoogleMapsUtil } from 'src/app/shared/utils/google-maps.util';

@Component({
  selector: 'laveo-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit {
  @Input() customer?: Customer;
  form: UntypedFormGroup;

  fileUploadOptions: any = {};

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    public readonly googleMapsUtil: GoogleMapsUtil
  ) {}

  get contactFormArray(): UntypedFormArray {
    return this.form.get('contacts') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.setForm();
    this.fileUploadOptions = imageFileUploadOptions;
    this.googleMapsUtil.load();
  }

  onFullAddressChange(address: google.maps.places.PlaceResult): void {
    if (!address) {
      return;
    }

    const streetNumber = address.address_components?.find(x => x.types.includes('street_number'));
    const street = address.address_components?.find(x => x.types.includes('route'));
    const postalCode = address.address_components?.find(x => x.types.includes('postal_code'));
    const city = address.address_components?.find(x => x.types.includes('locality'));
    const country = address.address_components?.find(x => x.types.includes('country'));
    const latitude = address?.geometry?.location?.lat();
    const longitude = address?.geometry?.location?.lng();

    if (street && streetNumber) {
      this.form.get('address')?.setValue( streetNumber.long_name + ' ' + street.long_name);
    } else if (street) {
      this.form.get('address')?.setValue(street.long_name);
    } else {
      this.form.get('address')?.setValue(null);
    }
    if (postalCode) {
      this.form.get('postalCode')?.setValue(postalCode.long_name);
    } else {
      this.form.get('postalCode')?.setValue(null);
    }
    if (city) {
      this.form.get('city')?.setValue(city.long_name);
    } else {
      this.form.get('city')?.setValue(null);
    }
    if (country) {
      this.form.get('country')?.setValue(country.short_name);
    } else {
      this.form.get('country')?.setValue(null);
    }
    if (latitude && longitude) {
      this.form.get('latitude')?.setValue(latitude);
      this.form.get('longitude')?.setValue(longitude);
    } else {
      this.form.get('latitude')?.setValue(null);
      this.form.get('longitude')?.setValue(null);
    }

    this.form.updateValueAndValidity();
  }

  customerSites(customer: Customer): string {
    if (customer?.customerSitesMetaData?.totalResults > 0) {
      return customer?.customerSitesMetaData?.totalResults > 1 ? customer?.customerSitesMetaData?.totalResults + ' sites associés' : customer?.customerSitesMetaData?.totalResults + ' site associé';
    }
    return 'Aucun site associé';
  }

  addLogo(event: { output: File }): void {
    const logoInput = this.form.get('logo');
    logoInput?.setValue(event.output);
    logoInput?.updateValueAndValidity();
  }

  removeLogo(): void {
    const logoInput = this.form.get('logo');
    logoInput?.setValue(null);
    logoInput?.updateValueAndValidity();
  }

  private setForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.customer?.id, []),
      active: this.formBuilder.control(this.customer?.active ?? true, []),
      name: this.formBuilder.control(this.customer?.name, [Validators.required]),
      fullAddress: this.formBuilder.control(this.customer?.fullAddress, []),
      address: this.formBuilder.control(this.customer?.address, []),
      postalCode: this.formBuilder.control(this.customer?.postalCode, []),
      city: this.formBuilder.control(this.customer?.city, []),
      country: this.formBuilder.control(this.customer?.country, []),
      latitude: this.formBuilder.control(this.customer?.latitude, []),
      longitude: this.formBuilder.control(this.customer?.longitude, []),
      contacts: this.formBuilder.array(this.customer?.contacts ?? [], []),
      logo: this.formBuilder.control(null, []),
      allowImportServices: this.formBuilder.control(this.customer?.allowImportServices ?? false, []),
    });
  }
}
