import { Type } from 'typeserializer';
import { Entity } from './entity';
import {Service} from "./service";

export class BatchProcessFailed extends Entity {
  message: string;
  object: Service;
}

export class BatchProcessService extends Entity {
  /** Liste des erreurs d'import */
  @Type([BatchProcessFailed])
  processFailed: BatchProcessFailed[];

  /** Liste des véhicules ajoutés */
  @Type([Service])
  processOk: Service[];
}
