import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NzUploadFile, NzUploadXHRArgs} from "ng-zorro-antd/upload";
import {of, Subscription, throwError} from "rxjs";
import {ApolloError} from "@apollo/client/core";
import {ApiErrorMessageUtil} from "../../../../shared/utils/api-error-message.util";
import {delay, switchMap} from "rxjs/operators";
import {UserService} from "../../../../shared/services/api/user.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {Title} from "@angular/platform-browser";
import {UserRoleType} from "../../../../shared/models/entities/user-role";
import {ServicesService} from "../../../../shared/services/api/services.service";
import {ProgressNotifierComponent} from "../../shared/progress-notifier/progress-notifier.component";
import {HttpClient} from "@angular/common/http";
import {DatePipe} from '@angular/common';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {BatchProcessFailed} from "../../../../shared/models/entities/batch-process-service";
import {Customer} from "../../../../shared/models/entities/customer";

@Component({
  selector: 'laveo-service-import',
  templateUrl: './service-import.component.html',
  styleUrl: './service-import.component.scss',
  providers: [DatePipe]
})
export class ServiceImportComponent implements OnInit, OnDestroy {

  @ViewChild('importNotifier') importNotifier: ProgressNotifierComponent;
  @ViewChild('exportNotifier') exportNotifier: ProgressNotifierComponent;
  userCanImport = false;
  actionLoading = false;
  customerId = null;
  // Import
  importFileList: NzUploadFile[] = [];
  importErrorModalVisible = false;
  importErrors?: BatchProcessFailed[] = [];

  excelPath = '/excel/laveo-service-template/';

  // Range of dates for invoice generation
  generateForm: UntypedFormGroup;

  //private currentRoleId: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userService: UserService,
    private readonly message: NzMessageService,
    private readonly servicesService: ServicesService,
    private readonly titleService: Title,
    private cdr: ChangeDetectorRef,
    private http: HttpClient
  ) {
    this.customerId = this.route.snapshot.params.id;
  }


  ngOnInit(): void {
    this.setTitle();
    this.loadRole();
    this.setGenerateForm();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  import = (item: NzUploadXHRArgs): Subscription => item.postFile as File ? this.servicesService.importServices(this.customerId!, item.postFile as File).subscribe({
    next: (response) => {
      if (response.data?.processOk?.length ?? 0 > 0) {
        this.message.success(`Import de ${response.data?.processOk?.length} prestation(s) réussie(s)`);
      } else {
        this.message.warning(`Aucune prestation importée. Vérifiez votre fichier`);
      }

      if (response.data?.processFailed?.length ?? 0 > 0) {
        this.importErrors = response.data?.processFailed;
        this.importErrorModalVisible = true;

        this.cdr.detectChanges();
      }

      if (item.onSuccess) {
        item.onSuccess(null, item.file, null);
        this.importFileList = [];
      }
    },
    error: (error: ApolloError) => {
      console.error(error);
      this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      if (item.onError) {
        item.onError(error, item.file);
      }
    }
  }) : of('').pipe(
    delay(500),
    switchMap(() => throwError(() => new Error('Erreur lors de l\'envoi. Réessayer')))
  ).subscribe({
    error: (error) => {
      if (item.onError) {
        item.onError(error, item.file);
      }
    }
  });

  handleChange(fileList: NzUploadFile[]): void {
    if (fileList?.length > 0) {
      this.importFileList = fileList.slice(-1);
    }
  }

  closeImportErrorModal(): void {
    this.importErrorModalVisible = false;
  }


  exportExcel() {
    this.resetAllProgresses();
    if (this.userCanImport) {

      this.http.post(this.excelPath + this.customerId + ".xlsx", {}, {responseType: 'blob'}).subscribe((response: Blob) => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(response);
        a.href = objectUrl;
        a.download = "laveo_prestations_modele.xlsx";
        a.style.display = 'none';
        document.body.append(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(objectUrl);
      });

    } else {
      this.message.error("Vous n'avez pas les droits pour télécharger le modèle.");
    }
  }

  protected resetAllProgresses() {
    this.exportNotifier.reset();
    this.importNotifier.reset();
  }

  private setGenerateForm(): void {
    this.generateForm = this.formBuilder.group({});
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {


      // Get the import right if the user is a customer
      if (role.type === UserRoleType.customer) {
        const customer = role.actor as Customer;
        this.userCanImport = (this.customerId == this.customerId && (customer.allowImportServices ?? false));

      } else this.userCanImport = role.type === UserRoleType.admin;
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Import de prestations');
  }
}
